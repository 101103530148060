import styles from "./theme-question.module.scss";

import ThemeAnswerImage from "../theme-answer-image/ThemeAnswerImage";
import ThemeAnswerWords from "../theme-answer-words/ThemeAnswerWords";
import ThemeAnswerVideo from "../theme-answer-video/ThemeAnswerVideo";

import { setFirstLetterCapitalized } from "../../_utils/text";
const ThemeQuestion = ({ question, answers, index }) => {
    return (
        <section className={styles.question} id={`question-${index + 1}`}>
            <h2 className={styles.question__title}>
                <span className={styles["question__title-number"]}>0{index + 1}</span>
                {setFirstLetterCapitalized(question)}
            </h2>
            <div className={styles.question__answers}>
                {answers.map((answer, answerIndex) => {
                    // console.log(answer.type);
                    if (answer.type === "image") {
                        return <ThemeAnswerImage key={answerIndex} answerIndex={answerIndex} questionIndex={index} {...answer} />;
                    } else if (answer.type === "words") {
                        return <ThemeAnswerWords key={answerIndex} answerIndex={answerIndex} questionIndex={index} {...answer} />;
                    } else if (answer.type === "video") {
                        return <ThemeAnswerVideo key={answerIndex} answerIndex={answerIndex} questionIndex={index} {...answer} />;
                    } else {
                        return null;
                    }
                })}
            </div>
        </section>
    );
};

export default ThemeQuestion;
