import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import styles from "./theme-content.module.scss";
import ThemeQuestion from "../theme-question/ThemeQuestion";
import ThemeNavigation from "../theme-navigation/ThemeNavigation";

import Container from "../../_globals/container/Container";
import { RichText } from "prismic-reactjs";

import { StickyContainer } from "react-sticky";

const ThemeContent = ({ content, introduction }) => {
    let showBackToTop = false;
    const refBackToTop = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (window.innerWidth < 900) {
                window.addEventListener("scroll", onScroll);
            }
        }, 1000);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onScroll = () => {
        var scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        if (scrollPos > 1000) {
            if (!showBackToTop) {
                gsap.to(refBackToTop.current, { duration: 0.5, y: 0, opacity: 1 });
                showBackToTop = true;
            }
        } else {
            if (showBackToTop) {
                gsap.to(refBackToTop.current, { duration: 0.5, y: 40, opacity: 0 });
                showBackToTop = false;
            }
        }
    };

    const onBackToTopClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div className={styles["theme-content"]}>
            <StickyContainer>
                <div className={styles["theme-content__top"]}>
                    <button className={styles["theme-content__top-cta"]} onClick={onBackToTopClick} ref={refBackToTop}>
                        ↑
                    </button>
                </div>

                <ThemeNavigation amountOfQuestions={content.length} />

                <div className={styles["theme-content-offset"]}>
                    {introduction && (
                        <Container>
                            <div className={`${styles["theme-content__introduction"]} ${styles["body"]}`}>
                                <RichText render={introduction} />
                            </div>
                        </Container>
                    )}

                    <Container>
                        <div className={styles["theme-content__main"]}>
                            {content.map((data, index) => {
                                return <ThemeQuestion key={index} index={index} question={data[0]} answers={data[1]} />;
                            })}
                        </div>
                    </Container>
                </div>
            </StickyContainer>
        </div>
    );
};

export default ThemeContent;
