import React, { useEffect, useState } from "react";
import styles from "../components/explore/explore-grid/explore-grid.module.scss";

import Prismic from "@prismicio/client";
import { client } from "../prismic-configuration";

import AsideLayout from "../components/_globals/aside/AsideLayout";
import Container from "../components/_globals/container/Container";
import SEO from "../components/_globals/seo/SEO";
import ExploreGrid from "../components/explore/explore-grid/ExploreGrid";
import Footer from "../components/_globals/footer/Footer";

const PageExplore = ({ authUser }) => {
    const [allData, setAllData] = useState(null);
    const [data, setData] = useState(null);
    const [activeFilter, setActiveFilter] = useState("all");

    useEffect(() => {
        const fetchData = async () => {
            const response = await client.query(Prismic.Predicates.at("document.type", "explore"));
            if (response) {
                setData(response.results[0].data.body);
                setAllData(response.results[0].data.body);
            }
        };
        fetchData();
    }, []);

    const onFilterClick = (title) => {
        if (title === "all") {
            setActiveFilter("all");
            setData(allData);
        } else {
            let filteredData = allData.filter((item) => {
                return item.primary.type === title;
            });
            setData(filteredData);
            setActiveFilter(title);
        }
    };

    if (data === null) {
        return null;
    }

    return (
        <>
            <SEO title='EXPLORE' />

            <div className={styles["page-content"]}>
                <Container>
                    <AsideLayout>
                        <div>
                            <h1>Explore</h1>
                            <div className={styles["explore-grid-filter"]}>
                                <button
                                    className={`${styles["explore-grid-filter__item"]} ${styles["tagline"]} ${
                                        activeFilter === "all" ? styles["explore-grid-filter__item--active"] : null
                                    }`}
                                    onClick={() => onFilterClick("all")}>
                                    All
                                </button>
                                <button
                                    className={`${styles["explore-grid-filter__item"]} ${styles["tagline"]} ${
                                        activeFilter === "news" ? styles["explore-grid-filter__item--active"] : null
                                    }`}
                                    onClick={() => onFilterClick("news")}>
                                    News
                                </button>
                                <button
                                    className={`${styles["explore-grid-filter__item"]} ${styles["tagline"]} ${
                                        activeFilter === "resources" ? styles["explore-grid-filter__item--active"] : null
                                    }`}
                                    onClick={() => onFilterClick("resources")}>
                                    Resources
                                </button>
                                <button
                                    className={`${styles["explore-grid-filter__item"]} ${styles["tagline"]} ${
                                        activeFilter === "event" ? styles["explore-grid-filter__item--active"] : null
                                    }`}
                                    onClick={() => onFilterClick("event")}>
                                    Event
                                </button>
                                <button
                                    className={`${styles["explore-grid-filter__item"]} ${styles["tagline"]} ${
                                        activeFilter === "features" ? styles["explore-grid-filter__item--active"] : null
                                    }`}
                                    onClick={() => onFilterClick("features")}>
                                    Features
                                </button>
                            </div>
                        </div>

                        <ExploreGrid body={data} />
                    </AsideLayout>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default PageExplore;
