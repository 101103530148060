import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import ButtonGhost from "../buttons/ButtonGhost";
import styles from "./cookie-notification.module.scss";

import Prismic from "@prismicio/client";
import { client, linkResolver } from "../../../prismic-configuration";

import { LocalStorage } from "../../_utils/localStorage";
import { LocalStorageKeys } from "../../_utils/localStorageKeys";
import { RichText } from "prismic-reactjs";

const CookieNotification = () => {
    const refCookie = useRef();
    const [globalData, setGlobalData] = useState(null);

    useEffect(() => {
        const isCookieSet = LocalStorage.get(LocalStorageKeys.COOKIE);
        if (isCookieSet === null) {
            const fetchData = async () => {
                const response = await client.query(Prismic.Predicates.at("document.type", "globals"));
                if (response) {
                    setGlobalData(response.results[0].data);
                    gsap.to(refCookie.current, { duration: 0.6, autoAlpha: 1, ease: "Power3.easeInOut" });
                }
            };
            fetchData();
        }
    }, []);

    const onAcceptClick = () => {
        gsap.to(refCookie.current, { duration: 0.6, autoAlpha: 0, ease: "Power3.easeInOut" });
        LocalStorage.set(LocalStorageKeys.COOKIE, "accept");
    };

    if (globalData === null) {
        return null;
    }

    return (
        <div
            className={styles["cookie-notification"]}
            ref={refCookie}
            style={{
                background: globalData.cookie_background_color ? globalData.cookie_background_color : "#2b2625",
            }}>
            <div className={styles["cookie-notification__content"]}>
                <p className='tagline'>{RichText.asText(globalData.cookie_notification_title)}</p>
                <RichText render={globalData.cookie_notification_text} linkResolver={linkResolver} />
            </div>
            <ButtonGhost clickHandler={onAcceptClick} text='Accept' />
        </div>
    );
};
export default CookieNotification;
