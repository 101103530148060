import React, { Component } from "react";
import styles from "../components/members/members-list.module.scss";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../firebase";
import { compose } from "recompose";

import Prismic from "@prismicio/client";
import { client } from "../prismic-configuration";

import * as ROUTES from "../constants/routes";

import MembersList from "../components/members/MembersList";
import AsideLayout from "../components/_globals/aside/AsideLayout";
import Container from "../components/_globals/container/Container";
import SEO from "../components/_globals/seo/SEO";
import { RichText } from "prismic-reactjs";
import Footer from "../components/_globals/footer/Footer";

const PageMembers = () => {
    return <Members />;
};

class MembersData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            membersList: [],
            data: null,
        };
    }

    componentDidMount() {
        this.props.firebase.auth.onAuthStateChanged((authUser) => {
            if (!authUser) {
                this.props.history.push(ROUTES.SIGN_IN);
            } else {
                this.updateUserData();
            }
        });

        this.fetchData();
    }

    fetchData = async () => {
        const response = await client.query(Prismic.Predicates.at("document.type", "members"));
        if (response) {
            this.setState({ data: response.results[0].data });
        }
    };

    updateUserData = async () => {
        let data = await this.props.firebase.firestore.collection("users").get();
        let members = [];
        data.docs.forEach((item) => {
            const userData = item.data();
            if (userData.include_memberslist) {
                members.push(userData);
            }
        });

        this.setState({
            membersList: [...members],
        });
    };

    render() {
        if (this.state.membersList.length === 0 || this.state.data === null) {
            return null;
        }

        return (
            <>
                <SEO title='MEMBERS' />

                <div className={styles["page-content"]}>
                    <Container>
                        <AsideLayout>
                            <h1>Members</h1>

                            <div>
                                <div className={styles["members-list__title"]}>
                                    <RichText render={this.state.data.signup_field} />
                                </div>
                                <MembersList members={this.state.membersList} />
                            </div>
                        </AsideLayout>
                    </Container>
                </div>

                <Footer />
            </>
        );
    }
}

const Members = compose(withRouter, withFirebase)(MembersData);

export default PageMembers;
