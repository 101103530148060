import React, { useState } from 'react';
import styles from './accordion.module.scss';
import { ReactComponent as IconArrowHead } from '../../../assets/svg/icons/icon-arrow-head.svg';

const Accordion = ({
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onAccordionButtonClick = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className={`${styles.accordion} ${isOpen ? styles['accordion--is-open'] : ''}`}>
      <button className={`${styles['accordion-button']}`} onClick={onAccordionButtonClick}>
        <div className={styles['accordion-button__title']}>{children[0]}</div>
        <div className={`${styles['accordion-button__arrow']}`}>
          <IconArrowHead />
        </div>
      </button>
      <div className={`${styles['accordion-options']}`}>
        {children[1]}
      </div>
    </div>
  )
}

export default Accordion;