function set(key, value) {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
        window.localStorage.setItem(key, value);
    }
}

function remove(key) {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
        window.localStorage.removeItem(key);
    }
}

function get(key) {
    const isBrowser = typeof window !== "undefined";
    if (!isBrowser) {
        return null;
    }

    try {
        const item = window.localStorage.getItem(key);
        return item;
    } catch {
        return null;
    }
}

export const LocalStorage = {
    get,
    remove,
    set,
};
