import React, { useState, useEffect } from "react";
import styles from "./theme-answer-image.module.scss";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSubmission } from "../../../hooks/useSubmission";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ThemeAnswerImage = ({ id, type, image, reflection, questionIndex, answerIndex }) => {
    const setSubmission = useSubmission();
    const pathname = useLocation().pathname;

    const [imagePath, setImagePath] = useState(null);
    const [imageWidth, setImageWidth] = useState(null);
    const [imageHeight, setImageHeight] = useState(null);

    useEffect(() => {
        if (image.length !== 0) {
            const firstImage = image[0][0];
            if (firstImage.thumbnails) {
                setImagePath(firstImage.thumbnails["large"].url);
                setImageWidth(firstImage.thumbnails["large"].width);
                setImageHeight(firstImage.thumbnails["large"].height);
            } else {
                setImagePath(firstImage.url);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOverlayClick = (e) => {
        setSubmission(questionIndex, answerIndex);
    };

    if (!imagePath) {
        return null;
    }

    return (
        <div className={`${styles["answer"]}`}>
            <Link to={`${pathname}?q=${questionIndex}&a=${answerIndex}`} onClick={onOverlayClick} className={styles["answer__overlay"]}>
                <span className='tagline'>VIEW →</span>
            </Link>

            {imagePath && (
                <div className={styles["answer__image"]}>
                    <LazyLoadImage src={imagePath} width={imageWidth} height={imageHeight} alt={reflection} />
                </div>
            )}
        </div>
    );
};

export default ThemeAnswerImage;
