export const getType = (type) => {
    let selectedType = Array.isArray(type) ? type[0] : type;

    switch (selectedType) {
        case "WORDS":
            return "words";

        case "UPLOAD AN IMAGE":
        case "UPLOAD MULTIPLE IMAGES":
            return "image";

        case "UPLOAD ZIP FILE":
            return "file";

        case "VIDEO":
            return "video";

        default:
            return "other";
    }
};

export const getName = (sharing, name) => {
    const sharingName = Array.isArray(sharing) ? sharing[0] : sharing;
    switch (sharingName) {
        case "Show my name":
            return name;

        case "Stay anonymous":
        default:
            return "Anonymous";
    }
};
