import React, { useState, useEffect } from 'react';
import { client } from '../prismic-configuration';

import styles from '../components/_globals/content/content.module.scss';
import Content from '../components/_globals/content/Content';

import SEO from '../components/_globals/seo/SEO';
import { Redirect } from 'react-router-dom';

import * as ROUTES from '../constants/routes';
import { RichText } from 'prismic-reactjs';

const PageGeneric = ({ match }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        const paramFromUrl = match.params.id;
        const response = await client.getByUID('generic', paramFromUrl);
        if (response) {
          setData(response.data)
        } else {
            setError(true);
        }
      }
      fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    if (error) {
        return <Redirect to={ROUTES.LANDING}/>
    }
    
    if (data === null) {
        return null;
    }

    return (
        <>
            <SEO title={RichText.asText(data.title)}/>

            <div className={styles['page-content']}>
                <Content {...data}/>
            </div>
        </>
    )
}

export default PageGeneric;