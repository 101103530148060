import Account from "../components/account/Account";
import Footer from "../components/_globals/footer/Footer";
import SEO from "../components/_globals/seo/SEO";

const PageAccount = () => (
    <>
        <SEO title='ACCOUNT' />
        <Account />
        <Footer />
    </>
);
export default PageAccount;
