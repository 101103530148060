import React from 'react';
import styles from './signout.module.scss';
import { withFirebase } from '../../firebase';
 
const SignOut = ({ firebase }) => (
  <button type="button" onClick={firebase.doSignOut} className={styles.tagline}>
    Sign Out
  </button>
);
 
export default withFirebase(SignOut);