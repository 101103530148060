import styles from "./members-list.module.scss";
import Accordion from "../_globals/accordion/Accordion";

const MembersList = ({ members }) => {
    if (!members || members.map === undefined) {
        return <p>The members list is currently not available.</p>;
    }

    return (
        <>
            <table className={styles["members-list"]}>
                <thead>
                    <tr>
                        <th className={styles["tagline"]}>Name</th>
                        <th className={styles["tagline"]}>Location</th>
                        <th className={styles["tagline"]}>Instagram</th>
                        <th className={styles["tagline"]}>Personal url</th>
                    </tr>
                </thead>
                <tbody>
                    {members.map((member, index) => {
                        if (member.fullName === "") {
                            return null;
                        }

                        return (
                            <tr key={index} className={styles["members-list__item"]}>
                                <td>{member.fullName}</td>
                                <td>{member.city.toUpperCase()}</td>
                                <td>
                                    {member.instagramHandle && (
                                        <a
                                            className={styles["link"]}
                                            href={`https://www.instagram.com/${member.instagramHandle}`}
                                            target='_blank'
                                            rel='noreferrer'>
                                            @{member.instagramHandle}
                                        </a>
                                    )}
                                </td>
                                <td>
                                    <a className={styles["link"]} href={member.portfolio} target='_blank' rel='noreferrer'>
                                        {member.portfolio}
                                    </a>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className={styles["members-list--mobile"]}>
                {members.map((member, index) => {
                    if (member.fullName === "") {
                        return null;
                    }

                    return (
                        <Accordion key={index}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className={`${styles["tagline"]} ${styles["members-list__column"]}`}>Name</td>
                                        <td className={styles["members-list__option-value"]}>{member.fullName}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className={styles["members-list-option__item"]}>
                                            <span className={`${styles["tagline"]} ${styles["members-list__column"]}`}>City</span>
                                            <span className={styles["members-list__option-value"]}>{member.city}</span>
                                        </td>
                                        <td className={styles["members-list-option__item"]}>
                                            <span className={`${styles["tagline"]} ${styles["members-list__column"]}`}>Insta</span>
                                            <p className={styles["members-list__option-value"]}>
                                                <a
                                                    className={styles["link"]}
                                                    href={`https://www.instagram.com/${member.instagramHandle}`}
                                                    target='_blank'
                                                    rel='noreferrer'>
                                                    @{member.instagramHandle}
                                                </a>
                                            </p>
                                        </td>
                                        <td className={styles["members-list-option__item"]}>
                                            <span className={`${styles["tagline"]} ${styles["members-list__column"]}`}>Url</span>
                                            <p className={styles["members-list__option-value"]}>
                                                <a className={styles["link"]} href={member.portfolio} target='_blank' rel='noreferrer'>
                                                    {member.portfolio}
                                                </a>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Accordion>
                    );
                })}
            </div>
        </>
    );
};

export default MembersList;
