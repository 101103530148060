import React, { Component } from "react";
import styles from "./register.module.scss";
import stylesButton from "../../_globals/buttons/button-ghost.module.scss";

import { Link, withRouter } from "react-router-dom";
import { withFirebase } from "../../../firebase";
import { compose } from "recompose";

import * as ROUTES from "../../../constants/routes";
import Container from "../../_globals/container/Container";

const Register = () => (
    <div className={styles["page-content"]}>
        <Container>
            <div className={styles["form-container"]}>
                <h1 className={styles["form-title"]}>Signup</h1>
                <SignUpForm />
            </div>
        </Container>
    </div>
);

const INITIAL_STATE = {
    username: "",
    email: "",
    passwordOne: "",
    passwordTwo: "",
    firstName: "",
    lastName: "",
    instagramHandle: "",
    portfolio: "",
    city: "",
    terms: false,
    newsletter: false,
    legalAge: false,
    members: true,
    error: null,
};

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = (event) => {
        event.preventDefault();

        if (this.state.passwordOne !== this.state.passwordTwo) {
            this.setState({
                error: "Passwords do not match.",
            });
        } else if (this.state.terms === false || this.state.newsletter === false || this.state.legalAge === false) {
            this.setState({
                error: "Terms, newsletter and legal age checkboxes are required.",
            });
        } else {
            this.doCreateUserWithEmailAndPassword();
        }
    };

    doCreateUserWithEmailAndPassword = (inputs) => {
        const { email, passwordOne, firstName, lastName, instagramHandle, portfolio, city, members } = this.state;

        return this.props.firebase.auth
            .createUserWithEmailAndPassword(email, passwordOne)
            .then((user) => {
                console.log(user);
                return this.props.firebase.firestore
                    .collection("users")
                    .doc(user.user.id)
                    .set({
                        firstName,
                        lastName,
                        fullName: firstName + " " + lastName,
                        instagramHandle,
                        portfolio,
                        city,
                        email,
                        include_memberslist: members,
                        tags: "CLO_CIRCLE_WEBSITE",
                    });
            })
            .then(() => {
                console.log("added!");
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.LANDING);
            })
            .catch((error) => {
                switch (error.code) {
                    case "auth/email-already-in-use":
                        this.setState({
                            error: `This email already exist in our database. Please try to login.`,
                        });
                        break;

                    case "auth/weak-password":
                        this.setState({
                            error: `Password should be at least 6 characters.`,
                        });
                        break;

                    default:
                        break;
                }
            });
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeCheckbox = (checkbox) => {
        this.setState({ terms: !checkbox });
    };

    render() {
        const {
            email,
            passwordOne,
            passwordTwo,
            firstName,
            lastName,
            instagramHandle,
            portfolio,
            city,
            terms,
            newsletter,
            error,
            legalAge,
            members,
        } = this.state;

        const isInvalid = passwordOne === "" || email === "" || passwordTwo === "";

        return (
            <form onSubmit={this.onSubmit}>
                {error && <p className={styles["form-error"]}>{error}</p>}

                <div className={styles["form-row"]}>
                    <label className={styles["form-row__label"]} htmlFor='email'>
                        Email address *
                    </label>
                    <input
                        className={styles["form-row__field"]}
                        id='email'
                        name='email'
                        value={email}
                        onChange={this.onChange}
                        type='email'
                        required
                        placeholder='joe@test.com'
                    />
                </div>
                <div className={styles["form-row"]}>
                    <label className={styles["form-row__label"]} htmlFor='password'>
                        Password *
                    </label>
                    <input
                        className={styles["form-row__field"]}
                        id='password'
                        name='passwordOne'
                        value={passwordOne}
                        onChange={this.onChange}
                        type='password'
                        required
                        placeholder='Password with minimum 8 characters'
                    />
                </div>
                <div className={styles["form-row"]}>
                    <label className={styles["form-row__label"]} htmlFor='passwordTwo'>
                        Confirm password *
                    </label>
                    <input
                        className={styles["form-row__field"]}
                        id='passwordTwo'
                        name='passwordTwo'
                        value={passwordTwo}
                        onChange={this.onChange}
                        type='password'
                        required
                        placeholder='Same password as the field above'
                    />
                </div>

                <div className={styles["register-optional"]}>
                    <div className={styles["form-row"]}>
                        <label className={styles["form-row__label"]} htmlFor='firstName'>
                            First name
                        </label>
                        <input
                            className={styles["form-row__field"]}
                            id='firstName'
                            name='firstName'
                            value={firstName}
                            onChange={this.onChange}
                            type='text'
                            placeholder='John'
                        />
                    </div>
                    <div className={styles["form-row"]}>
                        <label className={styles["form-row__label"]} htmlFor='lastName'>
                            Last name
                        </label>
                        <input
                            className={styles["form-row__field"]}
                            id='lastName'
                            name='lastName'
                            value={lastName}
                            onChange={this.onChange}
                            type='text'
                            placeholder='Doe'
                        />
                    </div>
                    <div className={`${styles["form-row"]} ${styles["form-row--handle"]}`}>
                        <label className={styles["form-row__label"]} htmlFor='instagramHandle'>
                            Instagram handle
                        </label>
                        <input
                            className={styles["form-row__field"]}
                            id='instagramHandle'
                            name='instagramHandle'
                            value={instagramHandle}
                            onChange={this.onChange}
                            type='text'
                            placeholder='joetestphotos'
                        />
                    </div>
                    <div className={styles["form-row"]}>
                        <label className={styles["form-row__label"]} htmlFor='portfolio'>
                            Portfolio or personal url
                        </label>
                        <input
                            className={styles["form-row__field"]}
                            id='portfolio'
                            name='portfolio'
                            value={portfolio}
                            onChange={this.onChange}
                            type='text'
                            placeholder='https://joetestphotos.com'
                        />
                    </div>
                    <div className={styles["form-row"]}>
                        <label className={styles["form-row__label"]} htmlFor='portfolio'>
                            Location
                        </label>
                        <input
                            className={styles["form-row__field"]}
                            id='city'
                            name='city'
                            value={city}
                            onChange={this.onChange}
                            type='text'
                            placeholder='Amsterdam'
                        />
                    </div>
                    <div className={styles["form-row"]}>
                        <input id='terms' name='terms' value={terms} onChange={() => this.setState({ terms: !terms })} type='checkbox' />
                        <label htmlFor='terms'>
                            By signing up for CLO CIRCLE, you agree to our <Link to='generic/terms-conditions'>terms and privacy policy</Link>. *
                        </label>
                    </div>
                    <div className={styles["form-row"]}>
                        <input
                            id='newsletter'
                            name='newsletter'
                            value={newsletter}
                            onChange={() => this.setState({ newsletter: !newsletter })}
                            type='checkbox'
                        />
                        <label htmlFor='newsletter'>By signing up for CLO CIRCLE, you agree to receive our monthly newsletter. *</label>
                    </div>
                    <div className={styles["form-row"]}>
                        <input
                            id='legalAge'
                            name='legalAge'
                            value={legalAge}
                            onChange={() => this.setState({ legalAge: !legalAge })}
                            type='checkbox'
                        />
                        <label htmlFor='legalAge'>I am above 13 years of age. *</label>
                    </div>
                    <div className={styles["form-row"]}>
                        <input
                            id='members'
                            name='members'
                            value={members}
                            checked={members}
                            onChange={() => this.setState({ members: !members })}
                            type='checkbox'
                        />
                        <label htmlFor='members'>Include me in the members directory on the website</label>
                    </div>
                </div>

                <div className={styles["form-buttons"]}>
                    <p className={styles["form-buttons__optional"]}>* required</p>
                    <button className={`${stylesButton["button-ghost"]} ${stylesButton["button-ghost--black"]}`} disabled={isInvalid} type='submit'>
                        <span>Sign up</span>
                    </button>

                    <p className={styles["form-buttons__message"]}>
                        Already have an account? <Link to={ROUTES.SIGN_IN}>Login here</Link>
                    </p>
                </div>
            </form>
        );
    }
}

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default Register;

export { SignUpForm };
