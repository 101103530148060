import styles from "./theme-hero.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { RichText } from "prismic-reactjs";
import { instagramUrl } from "../../_utils/text";

const ThemeHero = ({ date, title, description, thumbnail, thumbnailCredits }) => {
    return (
        <section className={styles["theme-hero"]}>
            <div className={styles["theme-hero-wrapper"]}>
                <div className={styles["theme-hero__content"]}>
                    <h3 className={styles["theme-hero__title"]}>{RichText.asText(title)}</h3>
                    <div className={styles["theme-hero__description"]}>{RichText.render(description)}</div>
                </div>

                <div className={styles["theme-hero__background"]}>
                    <LazyLoadImage src={thumbnail.url} alt={thumbnail.alt} width={thumbnail.dimensions.width} height={thumbnail.dimensions.height} />
                </div>

                <div className={`${styles["themes-background__credit"]} tagline`}>
                    {thumbnailCredits && <p>Submission by {instagramUrl(RichText.asText(thumbnailCredits), styles)}</p>}
                </div>

                <button onClick={() => window.scrollTo(0, window.innerHeight)} className={styles["theme-hero__scroller"]}>
                    →
                </button>
            </div>
        </section>
    );
};

export default ThemeHero;
