import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Prismic from "@prismicio/client";
import { client } from "../../../prismic-configuration";

import styles from "./header.module.scss";
import { Link } from "react-router-dom";
import SignOut from "../../signout/SignOut";
import * as ROUTES from "../../../constants/routes";

import { AuthUserContext } from "../../../session";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isWhite, setIsWhite] = useState(false);
    const [data, setData] = useState(null);
    const pathname = useLocation().pathname;

    useEffect(() => {
        const fetchData = async () => {
            const response = await client.query(Prismic.Predicates.at("document.type", "globals"));
            if (response) {
                setData(response.results[0].data);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (pathname === "/" || pathname === "/themes") {
            setIsWhite(true);
        } else {
            setIsWhite(false);
        }
    }, [pathname]);

    useEffect(() => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <header className={`${styles.header} ${isMenuOpen ? null : isWhite ? styles["header--is-white"] : null}`}>
            <AuthUserContext.Consumer>
                {(authUser) => (
                    <>
                        <div className={styles["logo"]}>
                            <Link to={"/"} className={styles.tagline}>
                                CLO CIRCLE
                            </Link>
                        </div>
                        <nav className={`${styles["navigation"]} ${styles["navigation--left"]}`}>
                            <ul className={styles["navigation-list"]}>
                                {authUser && data && (
                                    <li className={`${styles["navigation-list__item"]}`}>
                                        <a href={data.typeform_url.url} target='_blank' rel='noreferrer' className={`${styles.tagline}`}>
                                            Contribute
                                        </a>
                                    </li>
                                )}
                                <li className={`${styles["navigation-list__item"]}`}>
                                    <Link to={ROUTES.ABOUT} className={`${styles.tagline} ${pathname === "/about" ? styles["active"] : null}`}>
                                        About
                                    </Link>
                                </li>
                                {authUser && (
                                    <li className={`${styles["navigation-list__item"]} ${pathname === "/explore" ? styles["active"] : null}`}>
                                        <Link
                                            to={ROUTES.EXPLORE}
                                            className={`${styles.tagline} ${pathname === "/explore" ? styles["active"] : null}`}>
                                            Explore
                                        </Link>
                                    </li>
                                )}
                                {authUser && (
                                    <li className={`${styles["navigation-list__item"]} ${pathname === "/members" ? styles["active"] : null}`}>
                                        <Link
                                            to={ROUTES.MEMBERS}
                                            className={`${styles.tagline} ${pathname === "/members" ? styles["active"] : null}`}>
                                            Members
                                        </Link>
                                    </li>
                                )}
                                {authUser && (
                                    <li className={`${styles["navigation-list__item"]} ${pathname === "/account" ? styles["active"] : null}`}>
                                        <Link
                                            to={ROUTES.ACCOUNT}
                                            className={`${styles.tagline} ${pathname === "/account" ? styles["active"] : null}`}>
                                            Account
                                        </Link>
                                    </li>
                                )}
                                {authUser ? (
                                    <li className={`${styles["navigation-list__item"]} ${pathname === "/login" ? styles["active"] : null}`}>
                                        <SignOut />
                                    </li>
                                ) : (
                                    <>
                                        <li className={`${styles["navigation-list__item"]} ${pathname === "/login" ? styles["active"] : null}`}>
                                            <Link
                                                to={ROUTES.SIGN_IN}
                                                className={`${styles.tagline} ${pathname === "/login" ? styles["active"] : null}`}>
                                                Log In
                                            </Link>
                                        </li>
                                        {/* <li className={`${styles["navigation-list__item"]} ${pathname === "/register" ? styles["active"] : null}`}>
                                            <Link
                                                to={ROUTES.SIGN_UP}
                                                className={`${styles.tagline} ${pathname === "/register" ? styles["active"] : null}`}>
                                                Join the club
                                            </Link>
                                        </li> */}
                                    </>
                                )}
                            </ul>
                        </nav>

                        <button
                            className={`${styles["mobile-button"]} ${isMenuOpen ? styles["mobile-button--is-open"] : null}`}
                            onClick={() => {
                                setIsMenuOpen(!isMenuOpen);
                            }}>
                            <div className={styles["mobile-button__circle"]}></div>
                        </button>

                        <nav className={`${styles["navigation-mobile"]} ${isMenuOpen ? styles["navigation-mobile--is-open"] : null}`}>
                            <ul className={styles["navigation-mobile-list"]}>
                                {authUser && data && (
                                    <li className={`${styles["navigation-mobile-list__item"]}`}>
                                        <a href={data.typeform_url.url} target='_blank' rel='noreferrer' className={`${styles.tagline}`}>
                                            Contribute
                                        </a>
                                    </li>
                                )}
                                <li className={styles["navigation-mobile-list__item"]}>
                                    <Link to={ROUTES.ABOUT} className={`${styles.tagline} ${pathname === "/contribute" ? styles["active"] : null}`}>
                                        About
                                    </Link>
                                </li>
                                {authUser && (
                                    <li className={styles["navigation-mobile-list__item"]}>
                                        <Link
                                            to={ROUTES.EXPLORE}
                                            className={`${styles.tagline} ${pathname === "/explore" ? styles["active"] : null}`}>
                                            Explore
                                        </Link>
                                    </li>
                                )}
                                {authUser && (
                                    <li className={styles["navigation-mobile-list__item"]}>
                                        <Link
                                            to={ROUTES.MEMBERS}
                                            className={`${styles.tagline} ${pathname === "/members" ? styles["active"] : null}`}>
                                            Members
                                        </Link>
                                    </li>
                                )}
                                {authUser && (
                                    <li className={`${styles["navigation-mobile-list__item"]} ${pathname === "/account" ? styles["active"] : null}`}>
                                        <Link
                                            to={ROUTES.ACCOUNT}
                                            className={`${styles.tagline} ${pathname === "/account" ? styles["active"] : null}`}>
                                            Account
                                        </Link>
                                    </li>
                                )}
                                {authUser ? (
                                    <SignOut />
                                ) : (
                                    <>
                                        <li
                                            className={`${styles["navigation-mobile-list__item"]} ${
                                                pathname === "/login" ? styles["active"] : null
                                            }`}>
                                            <Link
                                                to={ROUTES.SIGN_IN}
                                                className={`${styles.tagline} ${pathname === "/login" ? styles["active"] : null}`}>
                                                Log In
                                            </Link>
                                        </li>
                                        <li
                                            className={`${styles["navigation-mobile-list__item"]} ${
                                                pathname === "/register" ? styles["active"] : null
                                            }`}>
                                            <Link
                                                to={ROUTES.SIGN_UP}
                                                className={`${styles.tagline} ${pathname === "/register" ? styles["active"] : null}`}>
                                                Sign up
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </nav>
                    </>
                )}
            </AuthUserContext.Consumer>
        </header>
    );
};

export default Header;
