import React, { useEffect } from "react";

import Prismic from "@prismicio/client";
import { client } from "../prismic-configuration";

import SEO from "../components/_globals/seo/SEO";

import HomeBackground from "../components/home/HomeBackground";
import HomeContent from "../components/home/HomeContent";
// import Intro from "../components/intro/Intro";

const PageHome = () => {
    const [data, setData] = React.useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await client.query(Prismic.Predicates.at("document.type", "home"));
            if (response) {
                setData(response.results[0].data);
            }
        };
        fetchData();
    }, []);

    if (data === null) {
        return null;
    }

    return (
        <>
            <SEO title='' />

            {/* <Intro/> */}

            <HomeBackground backgrounds={data.backgrounds} />
            <HomeContent text={data.description_text} />
        </>
    );
};

export default PageHome;
