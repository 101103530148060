import { RichText } from 'prismic-reactjs';
import * as ROUTES from '../../../constants/routes';

import ButtonGhost from '../../_globals/buttons/ButtonGhost';
import Container from '../../_globals/container/Container';
import styles from './about-introduction.module.scss';

import { AuthUserContext } from '../../../session';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AboutIntroduction = ({ data }) => {

    return (
        <div className={styles['about']}>
            <div className={`${styles['about-headline']} ${styles['about-section']}`}>
                <Container>
                    <h1 className={`${styles['about-headline__title']} ${styles['title']}`}>{RichText.asText(data.title)}</h1>
                </Container>
                <div className={`${styles['about-circle']} ${styles['about-circle--right']}`}></div>
            </div>

            <div className={`${styles['about-content']} ${styles['about-section']}`}>
                <div className={`${styles['about-circle']} ${styles['about-circle--left']}`}></div>
                <Container>
                    <div className={`${styles['about-content__text']} ${styles['body']} ${styles['about-content__text--right']}`}>
                        {RichText.render(data.first_paragraph)}
                    </div>
                </Container>
            </div>

            <div className={`${styles['about-content']} ${styles['about-section']}`}>
                <Container>
                    <div className={`${styles['about-content__text']} ${styles['body']}`}>
                        {RichText.render(data.second_paragraph)}
                    </div>
                    
                    {data.image_above_third_paragraph &&
                        <figure className={styles['about-content__image']}>
                            <LazyLoadImage
                                src={data.image_above_third_paragraph.url} 
                                alt={data.image_above_third_paragraph.alt}
                                width={data.image_above_third_paragraph.dimensions.width}
                                height={data.image_above_third_paragraph.dimensions.height}/>
                            <figcaption className={styles['about-content__image-caption']}>
                                <RichText render={data.image_above_third_paragraph_text}/>
                            </figcaption>
                        </figure>
                    }
                </Container>
            </div>

            <div className={`${styles['about-content']} ${styles['about-section']}`}>
                <Container>
                    <div className={`${styles['about-content__text']} ${styles['body']} ${styles['about-content__text--right']}`}>
                        {RichText.render(data.third_paragraph)}

                        <AuthUserContext.Consumer>
                            {authUser =>
                                <>
                                    {!authUser &&
                                        <div className={styles['about-content__text-button']}>
                                            <ButtonGhost 
                                            text="Join the club"
                                            color="black"
                                            link={ROUTES.SIGN_UP}/>
                                        </div>
                                    }
                                </>
                            }
                        </AuthUserContext.Consumer>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default AboutIntroduction;