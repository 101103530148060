import React, { useEffect, useState } from "react";
import styles from "./footer.module.scss";

import Prismic from "@prismicio/client";
import { client } from "../../../prismic-configuration";

import { Link } from "react-router-dom";
import { RichText } from "prismic-reactjs";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Container from "../container/Container";

const Footer = () => {
    const [globalData, setGlobalData] = useState(null);
    const [instagramFeed, setInstagramFeed] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await client.query(Prismic.Predicates.at("document.type", "globals"));
            if (response) {
                setGlobalData(response.results[0].data);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchInstagramData = async () => {
            setInstagramFeed(null);
        };
        fetchInstagramData();
    }, []);

    if (globalData === null) {
        return false;
    }

    return (
        <footer className={styles["footer"]}>
            <div className={styles["footer-content"]}>
                <Container>
                    <h2 className={styles["title"]}>{RichText.render(globalData.footer_text)}</h2>
                    <div className={`${styles["footer-content__info-cta"]} ${styles["title"]}`}>
                        <span className={styles["footer-content__info-arrow"]}>→</span>
                        {RichText.render(globalData.footer_cta)}
                    </div>
                </Container>
                {/* {photos &&
                    photos.map(({ id, caption, src, width, height, url }) => (
                        <a key={id} href={url}>
                            <img src={src} alt={caption} />
                        </a>
                    ))} */}
                {instagramFeed && (
                    <div className={styles["footer-instagram"]}>
                        {instagramFeed.map((post, index) => {
                            return (
                                <a href={post.url} rel='noopener noreferrer' target='_blank' key={index} className={styles["footer-instagram__item"]}>
                                    <LazyLoadImage
                                        src={post.thumbnail.src}
                                        alt={post.caption}
                                        width={post.thumbnail.config_width}
                                        height={post.thumbnail.config_height}
                                    />
                                </a>
                            );
                        })}
                    </div>
                )}
                <Container>
                    {globalData && (
                        <nav className={styles["footer-navigation"]}>
                            {globalData.footer_navigation.map((item, index) => {
                                return (
                                    <Link key={index} to={item.link_url.type + "/" + item.link_url.uid} className={styles["tagline"]}>
                                        {RichText.asText(item.link_text)}
                                    </Link>
                                );
                            })}
                        </nav>
                    )}
                </Container>
            </div>
        </footer>
    );
};

export default Footer;
