import { useContext } from 'react'
import { StoreContext } from './contexts'

export function useSubmission() {
  const {
    setLayout,
  } = useContext(StoreContext)

  async function setSubmission(questionIndex, answerIndex) {
    setLayout(prevState => {
      return { ...prevState, questionIndex: questionIndex, answerIndex: answerIndex }
    })
  }

  return setSubmission
}

