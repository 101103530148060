import styles from "./explore-item.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { RichText } from "prismic-reactjs";

const ExploreItem = ({ primary }) => {
    return (
        <div className={styles["explore-item"]}>
            <div>
                <span className={`${styles["explore-item__tagline"]} ${styles["tagline"]}`}>{primary.type}</span>

                {primary.image.url && (
                    <div className={styles["explore-item__image"]}>
                        <LazyLoadImage
                            src={primary.image.url}
                            alt={primary.image.alt}
                            width={primary.image.dimensions.width}
                            height={primary.image.dimensions.height}
                        />
                    </div>
                )}

                {primary.title.length !== 0 && <h2 className={`${styles["explore-item__title"]}`}>{RichText.asText(primary.title)}</h2>}

                {primary.description.length !== 0 && (
                    <div className={`${styles["explore-item__description"]} ${styles["body"]}`}>{RichText.asText(primary.description)}</div>
                )}
            </div>

            {primary.link.link_type !== "Any" && (
                <>
                    {primary.link.link_type === "Document" && (
                        <Link to={"/" + primary.link.type + "/" + primary.link.uid} className={`${styles["explore-item__cta"]} ${styles["tagline"]}`}>
                            Go Deeper <span>→</span>
                        </Link>
                    )}
                    {primary.link.link_type === "Web" && (
                        <a href={primary.link.url} className={`${styles["explore-item__cta"]} ${styles["tagline"]}`} target='_blank' rel='noreferrer'>
                            Go Deeper <span>→</span>
                        </a>
                    )}
                </>
            )}
        </div>
    );
};

export default ExploreItem;
