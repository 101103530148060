import { RichText } from 'prismic-reactjs';
import styles from './text.module.scss';

const Text = ({ alignment, text }) => {
    return (
        <div className={`${styles['text']} ${styles['richtext']} ${styles['body']} ${alignment ? styles['text--' + alignment.toLowerCase()] : ''}`}>
            <RichText render={text}/>
        </div>
    )
}

export default Text;