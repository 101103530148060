import React, { Component } from 'react';
import styles from './account.module.scss';
import stylesButton from '../_globals/buttons/button-ghost.module.scss';

import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../firebase'; 
import { compose } from 'recompose';

import PasswordChangeForm from '../auth/password-change/PasswordChange';
import Container from '../_globals/container/Container';

import * as ROUTES from '../../constants/routes';
 
const AccountPage = () => {
  return <Account/>
}

class AccountData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      fullName: '',
      portfolio: '',
      instagramHandle: '',
      city: '',
      error: ''
    }
  }

  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (!authUser) {
          this.props.history.push(ROUTES.SIGN_IN);
        } else {
          this.updateUserData(authUser);
        }
      },
    );
  }

  updateUserData = async (authUser) => {
    let data = await this.props.firebase.firestore.collection('users').get();
    data.docs.forEach(item=>{
      const userData = item.data();
      if (userData.email === authUser.email) {
        this.setState({
            email: authUser.email,
          fullName: userData.fullName,
          portfolio: userData.portfolio,
          instagramHandle: userData.instagramHandle,
          city: userData.city
        })
      }
    })
  }

  onDeleteAccountClick = () => {
    if (window.confirm('Are you sure you want to delete your account?')) {
      var user = this.props.firebase.auth.currentUser;
      const that = this;

      user.delete().then(function() {
        that.props.firebase.firestore.collection("users").where("email", "==", user.email).get()
        .then(querySnapshot => {
          querySnapshot.docs[0].ref.delete();
          that.props.history.push(ROUTES.LANDING);
        });
      }, function(error) {
        that.setState({error: error.message});
      });
    }
  }
  
  render() {
    return (
      <div className={styles['page-content']}>
          <Container>
            <h1 className={`${styles['form-title']} ${styles['form-title--left']}`}>Account</h1>
    
              <div className={styles['account-section']}>
                  <h2 className={styles['account-section__title']}>Current info</h2>
                  
                  <ul className={styles['account-section-list']}>
                      {this.state.email !== '' && 
                        <li className={styles['account-section-list__item']}>
                            <span className={styles['account-section-list__item-key']}>Email</span>
                            <span className={styles['account-section-list__item-value']}>{this.state.email}</span>
                        </li>
                      }
                      {this.state.fullName !== '' &&
                          <li className={styles['account-section-list__item']}>
                              <span className={styles['account-section-list__item-key']}>Full name</span>
                              <span className={styles['account-section-list__item-value']}>{this.state.fullName}</span>
                          </li>
                      }
                      {this.state.portfolio !== '' &&
                          <li className={styles['account-section-list__item']}>
                              <span className={styles['account-section-list__item-key']}>Portfolio url</span>
                              <p className={styles['account-section-list__item-value']}><a className={styles['link']} href={this.state.portfolio} target="_blank" rel="noreferrer">{this.state.portfolio}</a></p>
                          </li> 
                      }
                      {this.state.instagramHandle !== '' &&
                          <li className={styles['account-section-list__item']}>
                              <span className={styles['account-section-list__item-key']}>Instagram handle</span>
                              <p className={styles['account-section-list__item-value']}><a className={styles['link']} href={`https://www.instagram.com/${this.state.instagramHandle}`} target="_blank" rel="noreferrer">@{this.state.instagramHandle}</a></p>
                          </li>
                      }
                      {this.state.city &&
                          <li className={styles['account-section-list__item']}>
                              <span className={styles['account-section-list__item-key']}>City</span>
                              <span className={styles['account-section-list__item-value']}>{this.state.city}</span>
                          </li>
                      }
                  </ul>
              </div>
              <div className={styles['account-section']}>
                  <h2 className={styles['account-section__title']}>Update your password</h2>
                  <PasswordChangeForm />
              </div>
              <div className={styles['account-section']}>
                  <h2 className={styles['account-section__title']}>Delete your account</h2>

                  {this.state.error && <p className={`${styles['form-error']} ${styles['form-error--left']}`}>{this.state.error}</p>}

                  <button className={`${stylesButton['button-ghost']} ${stylesButton['button-ghost--black']}`} onClick={this.onDeleteAccountClick}>
                    <span>Delete account</span>
                  </button>
              </div>
          </Container>
      </div>
    )
  }
}


const Account = compose(
  withRouter,
  withFirebase,
)(AccountData);
 

export default AccountPage;