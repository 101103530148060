import React, { Component } from 'react';
import styles from './login.module.scss';
import stylesButton from '../../_globals/buttons/button-ghost.module.scss';

import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../../firebase';

import * as ROUTES from '../../../constants/routes';
import Container from '../../_globals/container/Container';
 
const SignInPage = () => (
    <div className={styles['page-content']}>
      <Container>
          <div className={styles['form-container']}>
              <h1 className={styles['form-title']}>Log in</h1>
              <SignInForm/>
          </div>
      </Container>
  </div>
);
 
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
 
class SignInFormBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { email, password } = this.state;
 
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/user-not-found':
            this.setState({
              error: `Sorry but we couldn't find your profile. Try creating an account first.`
            })
            break;

          case 'auth/wrong-password':
            this.setState({
              error: `Hmm, that password doesn't seem to match our records. Please try again or go to the forgot password section.`
            })
            break;
        
          default:
            break;
        }
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email, password, error } = this.state;
 
    const isInvalid = password === '' || email === '';
 
    return (
      <form onSubmit={this.onSubmit}>
        {error && <p className={styles['form-error']}>{error}</p>}
        
        <div className={styles['form-row']}>
            <label className={styles['form-row__label']} htmlFor="email">Email address</label>
            <input
                className={styles['form-row__field']}
                id="email"
                name="email"
                value={email}
                onChange={this.onChange}
                type="email"
                required
                placeholder="joe@test.com"/>
        </div>
        <div className={styles['form-row']}>
            <label className={styles['form-row__label']} htmlFor="password">Password</label>
            <input
                className={styles['form-row__field']}
                id="password"
                name="password"
                value={password}
                onChange={this.onChange}
                type="password"
                required
                placeholder="Password"/>
        </div>

        <div className={styles['form-buttons']}>
            <button className={`${stylesButton['button-ghost']} ${stylesButton['button-ghost--black']}`} disabled={isInvalid} type="submit">
                <span>Log in</span>
            </button>

            <p className={styles['form-buttons__message']}>Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign up here</Link></p>
            <p className={styles['form-buttons__message']}>Forgot your password? <Link to={ROUTES.PASSWORD_FORGET}>Click here</Link></p>
        </div>
      </form>
    );
  }
}
 
const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);
 
export default SignInPage;
 
export { SignInForm };