import React, { useState, useEffect, useRef } from "react";
import styles from "./theme-answer-words.module.scss";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSubmission } from "../../../hooks/useSubmission";

import { clampText } from "../../../vendor/Clamp";

const ThemeAnswerWords = ({ id, type, words, reflection, questionIndex, answerIndex }) => {
    const setSubmission = useSubmission();
    const pathname = useLocation().pathname;
    const [wordsTruncated, setWordsTruncated] = useState(null);
    const refWords = useRef();

    useEffect(() => {
        let usedWords = reflection;
        if (words) {
            usedWords = words;
        }

        if (usedWords && usedWords !== "") {
            setWordsTruncated(usedWords);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        truncateText();
        window.addEventListener("resize", truncateText);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordsTruncated]);

    const truncateText = () => {
        if (wordsTruncated) {
            const widthOfScreen = window.innerWidth;

            if (widthOfScreen > 780) {
                clampText(refWords.current, { clamp: window.innerWidth / 7 + "px" });
            } else {
                clampText(refWords.current, { clamp: window.innerWidth - 220 + "px" });
            }
        }
    };

    const onOverlayClick = (e) => {
        setSubmission(questionIndex, answerIndex);
    };

    return (
        <div className={`${styles["answer"]} ${styles["answer--words"]}`}>
            <Link to={`${pathname}?q=${questionIndex}&a=${answerIndex}`} onClick={onOverlayClick} className={styles["answer__overlay"]}>
                <span className='tagline'>READ →</span>
            </Link>

            <div className={`${styles["answer__words"]}`} ref={refWords}>
                {wordsTruncated}
            </div>
        </div>
    );
};

export default ThemeAnswerWords;
