import React, { useEffect, useState } from 'react';

import Prismic from '@prismicio/client'
import { client } from "../prismic-configuration";

import SEO from '../components/_globals/seo/SEO';
import ThemesCircle from '../components/themes/ThemesCircle';

const PageThemes = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          const response = await client.query(
            Prismic.Predicates.at("document.type", "theme"), {
                orderings: '[my.theme.theme_month desc]'
              }
          )
          if (response) {
            setData(response)
          }
        }
        fetchData();
    }, []);

    if (data === null) {
        return null;
    }  

    return (
        <>
            <SEO title="THEMES"/>
            
            <ThemesCircle 
                themes={data.results}/>
        </>
    )
}

export default PageThemes;