const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const getMonthFromTime = (time) => {
    const month = time.split('-')[1];
    return MONTHS[month-1];
}

export const getMonthFromDate = (date) => {
    const split = date.split('-');
    return split[1] + '/' + split[0];
}

export const getYearFromDate = (date) => {
    const split = date.split('-');
    return split[0];
}