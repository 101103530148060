import styles from './explore-grid.module.scss';
import ExploreItem from "../explore-item/ExploreItem";

const ExploreGrid = ({ body }) => {
    return (
        <div className={styles['explore-grid']}>
            {body.map((item, index) => {
                return <ExploreItem key={index} {...item}/>
            })}
        </div>
    )
}

export default ExploreGrid;