import { Link } from 'react-router-dom';
import styles from './button-ghost.module.scss';

const ButtonGhost = ({ 
    text, 
    color = null, 
    clickHandler = null,
    link = null
}) => {
    if (link) {
        return (
            <Link to={link} className={`${styles['button-ghost']} ${styles['button-ghost--link']} ${color ? styles[`button-ghost--${color}`] : ''}`}>
                <span className={styles.tagline}>{text}</span>
            </Link>
        )
    }

    return (
        <button className={`${styles['button-ghost']} ${color ? styles[`button-ghost--${color}`] : ''}`} onClick={clickHandler} enabled>
            <span className={styles.tagline}>{text}</span>
        </button>
    )
}

export default ButtonGhost;