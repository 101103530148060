import React, { Component } from 'react';
import styles from './password-forgot.module.scss';
import stylesButton from '../../_globals/buttons/button-ghost.module.scss';

import { Link } from 'react-router-dom';
 
import { withFirebase } from '../../../firebase';
import * as ROUTES from '../../../constants/routes';
import Container from '../../_globals/container/Container';
 
const PasswordForget = () => (
  <div className={styles['page-content']}>
    <Container>
        <div className={styles['form-container']}>
            <h1 className={styles['form-title']}>FORGOT YOUR PASSWORD?</h1>
            <PasswordForgetForm/>
        </div>
    </Container>
  </div>
);
 
const INITIAL_STATE = {
  email: '',
  error: null,
  success: ''
};
 
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { email } = this.state;
 
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        
        this.setState({ ...INITIAL_STATE, success: 'We have send you an email to reset your password.' });
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/user-not-found':
            this.setState({
              error: `Sorry but we couldn't find your profile. Try creating an account first.`
            })
            break;

          default:
            break;
        }
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email, success, error } = this.state;
 
    const isInvalid = email === '';
 
    return (
      <form onSubmit={this.onSubmit}>
        {success && <p className={styles['form-success']}>{success}</p>}
        {error && <p className={styles['form-error']}>{error}</p>}

        <div className={styles['form-row']}>
          <label className={styles['form-row__label']} htmlFor="email">Email address</label>
          <input
              className={styles['form-row__field']}
              id="email"
              name="email"
              value={email}
              onChange={this.onChange}
              type="email"
              placeholder="joe@test.com"/>
        </div>

        <div className={styles['form-buttons']}>
          <button className={`${stylesButton['button-ghost']} ${stylesButton['button-ghost--black']}`} disabled={isInvalid} type="submit">
              <span>Request new password</span>
          </button>

          <p className={styles['form-buttons__message']}>Remember your password? <Link to={ROUTES.SIGN_UP}>Log in here</Link></p>
        </div>
      </form>
    );
  }
}

 
export default PasswordForget;
 
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
 
export { PasswordForgetForm };