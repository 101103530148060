import Helmet from 'react-helmet';

const SEO_TITLE_BASE = 'CLO CIRCLE';
const SEO_DESCRIPTION_DEFAULT = 'CLO CIRCLE';
const SEO_KEYWORDS = 'CLO CIRCLE';
const SEO_IMAGE_DEFAULT = 'REPLACE_ME';

const SEO = ({ title, description, imageUrl }) => {
    const seoTitle = (title !== "" ? title + ' | ' : '') + SEO_TITLE_BASE
    const seoDescription = (description ? description : SEO_DESCRIPTION_DEFAULT);
    const seoImage = (imageUrl ? imageUrl : SEO_IMAGE_DEFAULT);

    return (
        <Helmet>
            <title>{seoTitle}</title>
            <meta name="description" content={seoDescription}/>
            <meta name="keywords" content={SEO_KEYWORDS}/>

            <meta property="og:title" content={seoTitle}/>
            <meta property="og:description" content={seoDescription}/>
            <meta property="og:image" content={seoImage}/>

            <meta property="twitter:title" content={seoTitle}/>
            <meta property="twitter:description" content={seoDescription}/>
            <meta property="twitter:image" content={seoImage}/>
        </Helmet>
    )
}

export default SEO;