import { RichText } from "prismic-reactjs";

import styles from "./article-content.module.scss";
import Container from "../_globals/container/Container";

import Text from "./Text";
import Image from "./Image";

const AboutContent = ({ title, body }) => {
    const Slices = (item) => {
        if (item.slice_type === "text") {
            return <Text {...item.primary} />;
        } else if (item.slice_type === "image") {
            return <Image {...item.primary} />;
        }
    };

    return (
        <div className={styles["article-content"]}>
            <Container>
                {title && (
                    <div className={styles["article-content__title"]}>
                        <RichText render={title} />
                    </div>
                )}
                {body.map((item, index) => {
                    return <Slices {...item} key={index} />;
                })}
            </Container>
        </div>
    );
};

export default AboutContent;
