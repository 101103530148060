import Prismic from "@prismicio/client";
// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = "https://clocircle.cdn.prismic.io/api/v2";

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = "";

export const client = Prismic.client(apiEndpoint, { accessToken });

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
    if (doc.type === "theme") {
        return "/theme/" + doc.uid;
    } else if (doc.type === "generic") {
        return "/generic/" + doc.uid;
    } else if (doc.type === "article") {
        return "/article/" + doc.uid;
    } else {
        return "/" + doc.uid;
    }
};

export const hrefResolver = (doc) => {
    if (doc.type === "theme") {
        return "/theme/" + doc.uid;
    } else {
        return "/" + doc.uid;
    }
};
