import { RichText } from "prismic-reactjs";
import styles from "./image.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Image = ({ alignment, caption, image }) => {
    return (
        <figure className={`${styles["image"]} ${alignment ? styles["image--" + alignment.toLowerCase()] : ""}`}>
            <LazyLoadImage src={image.url} alt={image.alt} width={image.dimensions.width} height={image.dimensions.height} />
            <figcaption>
                <RichText render={caption} />
            </figcaption>
        </figure>
    );
};

export default Image;
