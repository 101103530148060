import React, { useState } from 'react';

const InitialLayoutState = {
    questionIndex: '',
    answerIndex: ''
}

export const StoreContext = React.createContext({
    layout: InitialLayoutState,
    setLayout: () => {
      throw Error('You forgot to wrap this in a Provider object')
    },
})


export function StoreContextProvider({ children }) {
    const [layout, setLayout] = useState(InitialLayoutState);
  
    return (
        <StoreContext.Provider
            value={{
                layout,
                setLayout
            }}>
            {children}
        </StoreContext.Provider>
    )
}
