import React, { useEffect, useState } from "react";
import Airtable from "airtable";

import { client } from "../prismic-configuration";
import { RichText } from "prismic-reactjs";

import SEO from "../components/_globals/seo/SEO";

import ThemeHero from "../components/theme/theme-hero/ThemeHero";
import ThemeContent from "../components/theme/theme-content/ThemeContent";
import ThemeOverlay from "../components/theme/theme-overlay/ThemeOverlay";

import { getType, getName } from "../components/_utils/record";
import { useLocation } from "react-router-dom";
import Footer from "../components/_globals/footer/Footer";

const PageTheme = ({ match }) => {
    const [themeData, setThemeData] = useState([]);
    const [data, setData] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            const paramFromUrl = match.params.id;
            const response = await client.getByUID("theme", paramFromUrl);

            if (response.data) {
                setData(response.data);

                let arrResponses = [];

                const config = {
                    airtableApi:
                        process.env.REACT_APP_AIRTABLE_API || "patWbSXT1dSzxL90F.325c73b1e33b30fc5722f17eeaa3756eb986cfd06cef8b77fa2452eb322f3bd4",
                    airtableBase: process.env.REACT_APP_AIRTABLE_BASE || "appiVoxfPQzXS2KYa",
                };

                const baseName = RichText.asText(response.data.airtable_field);
                Airtable.configure({
                    endpointUrl: "https://api.airtable.com",
                    apiKey: config.airtableApi,
                });

                const base = Airtable.base(config.airtableBase);

                base(baseName)
                    .select({
                        maxRecords: 200,
                    })
                    .eachPage(
                        function page(records, fetchNextPage) {
                            records.forEach(function (record) {
                                const hasPermission = record.get("Permission");
                                if (hasPermission) {
                                    const data = {
                                        id: record.id,
                                        name: getName(record.get("Sharing"), record.get("Name")),
                                        type: getType(record.get("Response")),
                                        question: record.get("Question"),
                                        reflection: record.get("Reflection"),
                                        image: record.get("Image") ? [record.get("Image")] : [],
                                        words: record.get("Words"),
                                        video: record.get("Paste Vimeo link"),
                                        baseUrl: response.type + "/" + response.uid,
                                    };

                                    // addRecordToArray(record, null, arrResponses, response);

                                    const image1 = record.get("Image 1");
                                    if (image1) {
                                        data.image.push(image1);
                                        // addRecordToArray(record, image1, arrResponses, response);
                                    }
                                    const image2 = record.get("Image 2");
                                    if (image2) {
                                        data.image.push(image2);
                                        // addRecordToArray(record, image2, arrResponses, response);
                                    }
                                    const image3 = record.get("Image 3");
                                    if (image3) {
                                        data.image.push(image3);
                                        // addRecordToArray(record, image3, arrResponses, response);
                                    }
                                    const image4 = record.get("Image 4");
                                    if (image4) {
                                        data.image.push(image4);
                                        // addRecordToArray(record, image4, arrResponses, response);
                                    }
                                    const image5 = record.get("Image 5");
                                    if (image5) {
                                        data.image.push(image5);
                                        // addRecordToArray(record, image5, arrResponses, response);
                                    }
                                    const image6 = record.get("Image 6");
                                    if (image6) {
                                        data.image.push(image6);
                                        // addRecordToArray(record, image6, arrResponses, response);
                                    }

                                    arrResponses.push(data);
                                }
                            });

                            let result = arrResponses.reduce(function (r, a) {
                                if (a.question) {
                                    r[a.question] = r[a.question] || [];
                                    r[a.question].push(a);
                                }
                                return r;
                            }, Object.create(null));

                            setThemeData(Object.entries(result));

                            if (location.search.includes("q")) {
                                // GO TO PAGE
                            }

                            fetchNextPage();
                        },
                        function done(err) {
                            if (err) {
                                console.error(err);
                                return;
                            }
                        }
                    );
            } else {
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match]);

    if (themeData === null || data === null) {
        return null;
    }

    return (
        <>
            <SEO title={RichText.asText(data.theme_title)} description={RichText.asText(data.theme_description)} />

            <ThemeOverlay themeData={themeData} />

            <ThemeHero
                date={data.theme_month}
                title={data.theme_title}
                description={data.theme_description}
                thumbnail={data.background_image}
                thumbnailCredits={data.background_image_credits}
            />

            <ThemeContent introduction={data.theme_introduction} content={themeData} />

            <Footer />
        </>
    );
};

export default PageTheme;
