import styles from "./theme-navigation.module.scss";
const ThemeNavigation = ({ amountOfQuestions }) => {
    const getQuestions = () => {
        let questions = [];

        for (let i = 0; i < amountOfQuestions; i++) {
            let number = i + 1;
            questions.push(
                <a href={`#question-${number}`} className={styles["theme-navigation-list__item"]} key={i}>
                    {number < 10 ? "0" : ""}
                    {number}
                </a>
            );
        }

        return questions;
    };

    return (
        <aside className={styles["theme-navigation"]}>
            <p className={`${styles["theme-navigation__title"]} ${styles["tagline"]}`}>Questions</p>
            <div className={styles["theme-navigation-list"]}>{getQuestions()}</div>
        </aside>
    );
};

export default ThemeNavigation;
