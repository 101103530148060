export const setFirstLetterCapitalized = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const instagramUrl = (s, styles) => {
    const isInstagram = s.includes("@");

    if (isInstagram) {
        return (
            <a className={`${styles["link"]} prevent-nav`} href={`https://www.instagram.com/${s.substring(1)}`} target='_blank' rel='noreferrer'>
                {s}
            </a>
        );
    } else {
        return s;
    }
};

export const vimeoUrl = (url) => {
    const regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    const match = url.match(regExp);

    if (match) {
        return "https://player.vimeo.com/video/" + match[2];
    } else {
        return null;
    }
};
