import React, { Component } from 'react';
import styles from './password-change.module.scss';
import stylesButton from '../../_globals/buttons/button-ghost.module.scss';
 
import { withFirebase } from '../../../firebase';
 
const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
  success: null
};
 
class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { passwordOne } = this.state;
 
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE, success: 'Your password is updated.' });
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { passwordOne, passwordTwo, error, success } = this.state;
 
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';
 
    return (
      <form onSubmit={this.onSubmit}>
        {success && <p className={styles['form-success']}>{success}</p>}
        {error && <p className={styles['form-error']}>{error}</p>}
        
        <div className={styles['form-row']}>
          <label className={styles['form-row__label']} htmlFor="newPassword">New password *</label>
          <input
            className={styles['form-row__field']}
            id="newPassword"
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="New Password"
          />
        </div>
        <div className={styles['form-row']}>
          <label className={styles['form-row__label']} htmlFor="confirmNewPassword">Confirm new password *</label>
          <input
            className={styles['form-row__field']}
            id="confirmNewPassword"
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm New Password"
          />
        </div>

        <div className={styles['form-buttons']}>
          <p className={styles['form-buttons__optional']}>* required</p>
        
          <button className={`${stylesButton['button-ghost']} ${stylesButton['button-ghost--black']}`} disabled={isInvalid} type="submit">
            <span>Reset My Password</span>
          </button>
        </div>
      </form>
    );
  }
}
 
export default withFirebase(PasswordChangeForm);