import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import styles from "./themescircle.module.scss";

import { RichText } from "prismic-reactjs";
import { hrefResolver, linkResolver } from "../../prismic-configuration";
import { getMonthFromTime, getYearFromDate } from "../_utils/time";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import { instagramUrl } from "../_utils/text";

const ThemesCircle = ({ themes }) => {
    const [activeCircle, setActiveCircle] = useState(0);
    const [isAnimatingOut, setAnimatingOut] = useState(false);
    const refBackgrounds = useRef();
    const refCircles = useRef();
    const refInfo = useRef();
    const refArrow = useRef();

    const history = useHistory();
    useEffect(() => {
        const tl = gsap.timeline();
        tl.fromTo(
            refCircles.current.children,
            { scale: 0, opacity: 0 },
            {
                duration: 1.5,
                scale: 1,
                opacity: 0.5,
                ease: "back.out(2)",
                stagger: {
                    amount: 0.2,
                },
            }
        );
    }, []);

    const onCircleMouseEnter = (index) => {
        if (isAnimatingOut) return;
        let circleIndex = activeCircle;
        const tl = gsap.timeline();
        tl.to([refBackgrounds.current.children[circleIndex], refInfo.current.children[circleIndex]], { duration: 0.5, autoAlpha: 0 });
        circleIndex = index;
        tl.fromTo(
            [refBackgrounds.current.children[circleIndex], refInfo.current.children[circleIndex]],
            { autoAlpha: 0 },
            { duration: 0.5, autoAlpha: 1 },
            "<"
        );
        setActiveCircle(circleIndex);
    };

    const onCircleClick = (theme) => {
        setAnimatingOut(true);
        const items = [...refCircles.current.children].reverse();
        const tl = gsap.timeline({ onComplete: goToTheme, onCompleteParams: [theme] });
        tl.to([refInfo.current, refArrow.current], { duration: 0.6, autoAlpha: 0 });
        tl.to(items, {
            duration: 1.5,
            scale: 0,
            opacity: 0,
            ease: "back.in(2)",
            stagger: {
                amount: 0.2,
            },
        });
    };

    const goToTheme = (theme) => {
        history.push(theme.type + "/" + theme.uid);
    };

    return (
        <>
            <div className={styles["themes-desktop"]}>
                <div className={styles["themes-background"]} ref={refBackgrounds}>
                    {themes.map((theme, index) => {
                        return (
                            <img
                                key={index}
                                className={styles["themes-background__image"]}
                                src={theme.data.background_image.url}
                                alt={theme.data.background_image.alt}
                                width={theme.data.background_image.dimensions.width}
                                height={theme.data.background_image.dimensions.height}
                            />
                        );
                    })}
                </div>
                {RichText.asText(themes[activeCircle].data.background_image_credits).length !== 0 && (
                    <div className={`${styles["themes-background__credit"]} tagline`}>
                        <p>Submission by {instagramUrl(RichText.asText(themes[activeCircle].data.background_image_credits), styles)}</p>
                    </div>
                )}
                <div className={styles["themes"]}>
                    <div className={styles["themes-wrapper"]}>
                        <div className={styles["theme-circles"]} ref={refCircles}>
                            {themes.map((theme, index) => {
                                if (index > 8) {
                                    return null;
                                }
                                const isActive = activeCircle === index;
                                return (
                                    <div
                                        className={`${styles["theme-circles__item"]} ${isActive ? styles["theme-circles__item--active"] : ""}`}
                                        key={index}
                                        onMouseEnter={() => onCircleMouseEnter(index)}
                                        onClick={() => onCircleClick(theme)}>
                                        <div className={styles["theme-circles__item-arrow"]} ref={refArrow}>
                                            <div>&rarr;</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles["theme-active"]} ref={refInfo}>
                            {themes.map((theme, index) => {
                                return (
                                    <div className={styles["theme-active-wrapper"]} key={index}>
                                        <h2 className={styles["theme-active__name"]}>{RichText.asText(theme.data.theme_title)}</h2>
                                        <div className={`${styles["theme-active__month"]} ${styles["tagline"]}`}>
                                            <span>{getMonthFromTime(theme.data.theme_month)}</span>
                                            <span>{getYearFromDate(theme.data.theme_month)}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles["themes-mobile"]}>
                <div className={styles["themes-blocks"]}>
                    {themes.map((theme, index) => {
                        return (
                            <div key={index} className={styles["themes-blocks__item"]}>
                                <h2 className={styles["theme-blocks__item-title"]}>{RichText.asText(theme.data.theme_title)}</h2>
                                <span className={`${styles["theme-blocks__item-month"]} ${styles["tagline"]}`}>
                                    {getMonthFromTime(theme.data.theme_month)} {getYearFromDate(theme.data.theme_month)}
                                </span>
                                {RichText.asText(theme.data.background_image_credits).length !== 0 && (
                                    <div className={`${styles["themes-background__credit"]} tagline`}>
                                        <p>Submission by {instagramUrl(RichText.asText(theme.data.background_image_credits), styles)}</p>
                                    </div>
                                )}
                                <Link to={hrefResolver(theme)} as={linkResolver(theme)} className={`${styles["theme-blocks__item-arrow"]}`}>
                                    <div>&rarr;</div>
                                </Link>

                                <LazyLoadImage
                                    key={index}
                                    className={styles["themes-background__image"]}
                                    src={theme.data.background_image.url}
                                    alt={theme.data.background_image.alt}
                                    width={theme.data.background_image.dimensions.width}
                                    height={theme.data.background_image.dimensions.height}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default ThemesCircle;
