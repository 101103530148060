import styles from './content.module.scss';
import { RichText } from 'prismic-reactjs';
import Container from '../container/Container';

const Content = ({ title, content }) => {
    return (
        <Container>
            <div className={styles['content']}>
                {title &&
                    <div className={styles['content-title']}>
                        <RichText render={title}/>
                    </div>
                }
                {content &&
                    <div className={`${styles['content-richtext']} ${styles['richtext']}`}>
                        <RichText render={content}/>
                    </div>
                }
            </div>
        </Container>
    )
}

export default Content;