import React, { useEffect, useRef } from "react";
import gsap from "gsap";

import styles from "./homebackground.module.scss";
import { RichText } from "prismic-reactjs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { instagramUrl } from "../_utils/text";

const TIMER = 5000;

const HomeBackground = ({ backgrounds }) => {
    let length = backgrounds.length;
    let index = 0;

    const refBackgrounds = useRef();
    const refCredits = useRef();

    useEffect(() => {
        const interval = setInterval(() => {
            const tl = gsap.timeline();
            tl.to([refBackgrounds.current.children[index], refCredits.current.children[index]], { duration: 1.5, autoAlpha: 0 });
            // eslint-disable-next-line react-hooks/exhaustive-deps
            index = index >= length - 1 ? 0 : (index += 1);
            tl.fromTo(
                [refBackgrounds.current.children[index], refCredits.current.children[index]],
                { autoAlpha: 0 },
                { duration: 1.5, autoAlpha: 1 },
                "<0.5"
            );
        }, TIMER);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles["home-background"]}>
            <div className={styles["backgrounds"]} ref={refBackgrounds}>
                {backgrounds.map((background, index) => {
                    return (
                        <LazyLoadImage
                            className={styles["backgrounds__image"]}
                            key={index}
                            src={background.background_image.url}
                            alt={background.background_image.alt}
                            width={background.background_image.dimensions.width}
                            height={background.background_image.dimensions.height}
                        />
                    );
                })}
            </div>
            <div className={styles["credits"]} ref={refCredits}>
                {backgrounds.map((credit, index) => {
                    const creditName = RichText.asText(credit.background_image_credits);
                    return (
                        <div className={styles["credits__item"]} key={index}>
                            <div className='tagline'>
                                <RichText render={credit.theme_indication} />
                            </div>
                            <div className='tagline'>Submission by {instagramUrl(creditName, styles)}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HomeBackground;
