import React, { useEffect, useRef } from 'react';
import styles from './about-pillars.module.scss';
import stylesAbout from '../about-introduction/about-introduction.module.scss';
import gsap from 'gsap';

import Container from '../../_globals/container/Container';
import { RichText } from 'prismic-reactjs';

const AboutPillars = ({ data }) => {


    
    const refPillars = useRef();
    const refPillar1 = useRef();
    const refPillar3 = useRef();
    
    const refPillar1Text = useRef();
    const refPillar2Text = useRef();
    const refPillar3Text = useRef();
    const refExtra = useRef();
    
    useEffect(() => {
        const tl = gsap.timeline();
        tl.fromTo(refPillar1.current, { xPercent: 100 }, {
            xPercent: 0,
            scrollTrigger: {
                trigger: refPillars.current,
                start: "top 100%",
                scrub: true
            },
        });
        tl.fromTo(refPillar3.current, { xPercent: -100 }, {
            xPercent: 0,
            scrollTrigger: {
                trigger: refPillars.current,
                start: "top 100%",
                scrub: true
            },
        }, '<');
        tl.fromTo([refPillar1Text.current, refPillar2Text.current, refPillar3Text.current], { opacity: 0 }, { 
            opacity: 1,
            scrollTrigger: {
                trigger: refExtra.current,
                start: "top 120%",
                end: "top 50%",
                scrub: true
            }
         });
    }, [])

    return (
        <div className={styles['about-pillars']}>
            <div className={`${stylesAbout['about-headline']} ${styles['about-section']}`}>
                <Container>
                    <h2 className={`${stylesAbout['about-headline__title']} ${styles['title']}`}>
                        {RichText.asText(data.three_pillars_title)}
                    </h2>
                </Container>
            </div>

            <div className={styles['pillars']} ref={refPillars}>
                <div className={styles['pillar']} ref={refPillar1}>
                    <span className={styles['pillar-text']} ref={refPillar1Text}>{RichText.asText(data.pillar_1_title)}</span>
                </div>
                <div className={styles['pillar']}>
                    <span className={styles['pillar-text']} ref={refPillar2Text}>{RichText.asText(data.pillar_2_title)}</span>
                </div>
                <div className={styles['pillar']} ref={refPillar3}>
                    <span className={styles['pillar-text']} ref={refPillar3Text}>{RichText.asText(data.pillar_3_title)}</span>
                </div>
            </div>

            <Container>
                <ul className={styles['pillars-list']}>
                    <li className={styles['pillars-list__item']}  ref={refExtra}>
                        <h3 className={`${styles['pillars-list__item-title']} ${styles['title']}`}>{RichText.asText(data.pillar_1_title)}</h3>
                        <div className={`${styles['pillars-list__item-description']} ${styles['body']}`}>
                            {RichText.render(data.pillar_1_description)}
                        </div>
                    </li>
                    <li className={styles['pillars-list__item']}>
                        <h3 className={`${styles['pillars-list__item-title']} ${styles['title']}`}>{RichText.asText(data.pillar_2_title)}</h3>
                        <div className={`${styles['pillars-list__item-description']} ${styles['body']}`}>
                            {RichText.render(data.pillar_2_description)}
                        </div>
                    </li>
                    <li className={styles['pillars-list__item']} >
                        <h3 className={`${styles['pillars-list__item-title']} ${styles['title']}`}>{RichText.asText(data.pillar_3_title)}</h3>
                        <div className={`${styles['pillars-list__item-description']} ${styles['body']}`}>
                            {RichText.render(data.pillar_3_description)}
                        </div>
                    </li>
                </ul>
            </Container>
        </div>
    )
}

export default AboutPillars;