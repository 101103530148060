import { RichText } from "prismic-reactjs";

import ButtonGhost from "../_globals/buttons/ButtonGhost";
import styles from "./homecontent.module.scss";
import * as ROUTES from "../../constants/routes";

import { AuthUserContext } from "../../session";

const HomeContent = ({ text }) => {
    return (
        <div className={styles["home-content"]}>
            <h2 className={styles["home-content__title"]}>{RichText.render(text)}</h2>

            <AuthUserContext.Consumer>
                {(authUser) => (
                    <>
                        <ButtonGhost text={authUser ? "Explore our themes" : "Join the club"} link={authUser ? ROUTES.THEMES : ROUTES.SIGN_UP} />
                    </>
                )}
            </AuthUserContext.Consumer>
        </div>
    );
};

export default HomeContent;
