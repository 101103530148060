import styles from './aside-layout.module.scss';

const AsideLayout = ({ children }) => {
    return (
        <div className={styles['aside-layout']}>
            <aside className={styles['aside-layout__aside']}>
                <div className={styles['aside-layout__aside-scroll']}>
                    {children[0]}
                </div>
            </aside>
            <div className={styles['aside-layout__content']}>
                {children[1]}
            </div>
        </div>  
    )
}

export default AsideLayout;