export const LANDING = '/';
export const SIGN_UP = '/register';
export const SIGN_IN = '/login';
export const PASSWORD_FORGET = '/pw-forget';

export const ABOUT = '/about';
export const CONTRIBUTE = '/contribute';
export const MEMBERS = '/members';
export const EXPLORE = '/explore';
export const THEMES = '/themes';
export const ACCOUNT = '/profile';

export const THEME = '/theme/:id';
export const GENERIC = '/generic/:id';
export const ARTICLE = '/article/:id';