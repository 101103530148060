import React, { useEffect, useRef } from "react";
import styles from "./theme-answer-video.module.scss";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSubmission } from "../../../hooks/useSubmission";

const ThemeAnswerVideo = ({ id, type, words, reflection, questionIndex, answerIndex }) => {
    const setSubmission = useSubmission();
    const pathname = useLocation().pathname;
    const refWords = useRef();

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOverlayClick = (e) => {
        setSubmission(questionIndex, answerIndex);
    };

    return (
        <div className={`${styles["answer"]} ${styles["answer--words"]}`}>
            <Link to={`${pathname}?q=${questionIndex}&a=${answerIndex}`} onClick={onOverlayClick} className={styles["answer__overlay"]}>
                <span className='tagline'>WATCH →</span>
            </Link>

            <div className={`${styles["answer__video"]} ${styles["answer__words"]}`} ref={refWords}>
                <svg viewBox='0 0 24 24'>
                    <path d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z' />
                </svg>
            </div>
        </div>
    );
};

export default ThemeAnswerVideo;
