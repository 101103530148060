import React, { useEffect, useState, useRef } from "react";

import Container from "../../_globals/container/Container";
import styles from "./theme-overlay.module.scss";
import gsap from "gsap";

import { useLayout } from "../../../hooks/useLayout";
import { useSubmission } from "../../../hooks/useSubmission";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { instagramUrl, vimeoUrl } from "../../_utils/text";

const ThemeOverlay = ({ themeData }) => {
    const pathname = useLocation().pathname;

    const [submissionData, setSubmissionData] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const { questionIndex, answerIndex } = useLayout();
    const setSubmission = useSubmission();

    const [prevQuestion, setPrevQuestion] = useState(0);
    const [prevAnswer, setPrevAnswer] = useState(0);
    const [nextQuestion, setNextQuestion] = useState(0);
    const [nextAnswer, setNextAnswer] = useState(0);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // const refPagination = useRef();
    const refImages = useRef();

    // let currentDirection = "right";

    useEffect(() => {
        // window.addEventListener("keydown", onKeyDownHandler);
        // window.addEventListener("click", onMouseClickHandler);
        return () => {
            window.removeEventListener("keydown", onKeyDownHandler);
            window.removeEventListener("click", onMouseClickHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionData]);

    useEffect(() => {
        if (themeData === null || questionIndex === -1 || answerIndex === -1) {
            return;
        }

        const questionData = themeData[questionIndex];
        console.log(questionData);
        if (questionData) {
            const answerData = questionData[1][answerIndex];
            if (answerData) {
                setSubmissionData(answerData);

                if (answerData.type === "image") {
                    if (answerData.image.length !== 0) {
                        if (answerData.image[0][0].thumbnails) {
                            setImagePath(answerData.image[0][0].thumbnails["large"].url);
                        } else {
                            setImagePath(answerData.image[0][0].url);
                        }
                    } else {
                        setImagePath(null);
                    }
                } else {
                    setImagePath(null);
                }
            }

            const amountOfQuestions = themeData.length;

            let newIndex = answerIndex;
            let currentQuestionLength = themeData[questionIndex][1].length;

            let prevIndex = newIndex - 1;
            let prevQ = questionIndex;
            if (prevIndex <= 0) {
                if (prevQ === 0) {
                    prevQ = 0;
                    prevIndex = 0;
                } else {
                    const previousQuestionLength = themeData[prevQ - 1][1].length;
                    prevQ -= 1;
                    prevIndex = previousQuestionLength - 1;
                }
            }
            setPrevQuestion(prevQ);
            setPrevAnswer(prevIndex);

            let nextIndex = newIndex + 1;
            let nextQ = questionIndex;
            if (nextIndex >= currentQuestionLength) {
                nextIndex = 0;
                nextQ += 1;
                if (nextQ > amountOfQuestions) {
                    nextQ = amountOfQuestions;
                } else {
                    setNextQuestion(nextQ);
                }
            }
            setNextQuestion(nextQ);
            setNextAnswer(nextIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionIndex, answerIndex]);

    // const onMouseEnterHandler = () => {
    //     document.body.style.cursor = "none";
    //     gsap.fromTo(refPagination.current, { autoAlpha: 0 }, { duration: 0.2, autoAlpha: 1 });
    // };

    // const onMouseMoveHandler = (e) => {
    //     if (submissionData === null) return;

    //     const currX = e.pageX;
    //     const currY = e.clientY;

    //     const halfOfScreen = window.innerWidth / 2;

    //     refPagination.current.style.left = currX + "px";
    //     refPagination.current.style.top = currY + "px";

    //     if (currX < halfOfScreen) {
    //         if (currentDirection !== "left") {
    //             gsap.to(refPagination.current, { duration: 0.2, scaleX: -1 });
    //             currentDirection = "left";
    //         }
    //     } else {
    //         if (currentDirection !== "right") {
    //             gsap.to(refPagination.current, { duration: 0.2, scaleX: 1 });
    //             currentDirection = "right";
    //         }
    //     }
    // };

    // const onMouseLeaveHandler = () => {
    //     document.body.style.cursor = "default";
    //     gsap.to(refPagination.current, { duration: 0.2, autoAlpha: 0 });
    // };

    const onMouseClickHandler = (e) => {
        if (submissionData === null) return;
        if (e.target.classList.contains("prevent-nav")) return;
        if (window.innerWidth <= 768) return;

        const currX = e.pageX;
        const halfOfScreen = window.innerWidth / 2;

        if (currX < halfOfScreen) {
            onNavigateSlide("left");
        } else {
            onNavigateSlide("right");
        }
    };

    const onKeyDownHandler = (e) => {
        if (submissionData === null) return;

        switch (e.keyCode) {
            case 37:
                onNavigateSlide("left");
                break;

            case 39:
                onNavigateSlide("right");
                break;

            case 27:
                onClose();
                break;

            default:
                break;
        }
    };

    const onNavigateSlide = (direction) => {
        switch (direction) {
            case "left":
                setSubmission(prevQuestion, prevAnswer);
                break;

            case "right":
                setSubmission(nextQuestion, nextAnswer);
                break;

            default:
                break;
        }
    };

    const onClose = () => {
        document.body.style.cursor = "default";
        setSubmissionData(null);
        setSubmission(-1, -1);
    };

    const onImageSlide = (direction) => {
        const images = refImages.current.children;
        const amountOfImages = images.length;

        let currentIndex = currentImageIndex;
        const currentImage = images[currentIndex];

        const tl = gsap.timeline();
        tl.to(currentImage, { duration: 0.6, xPercent: direction === "left" ? 100 : -100, autoAlpha: 0, ease: "Power.easeInOut" });

        if (direction === "left") {
            currentIndex -= 1;
            if (currentIndex < 0) {
                currentIndex = amountOfImages - 1;
            }
        } else {
            currentIndex += 1;
            if (currentIndex >= amountOfImages) {
                currentIndex = 0;
            }
        }

        const nextImage = images[currentIndex];
        tl.fromTo(
            nextImage,
            { xPercent: direction === "left" ? -100 : 100, autoAlpha: 0 },
            { duration: 0.6, xPercent: 0, autoAlpha: 1, ease: "Power.easeInOut" },
            "<"
        );

        setCurrentImageIndex(currentIndex);
    };

    if (submissionData === null || questionIndex === -1 || answerIndex === -1) {
        return null;
    }

    return (
        <div className={styles["theme-overlay"]}>
            {/* <div className={styles["theme-overlay__pagination"]} ref={refPagination}>
                →
            </div> */}

            <div className={`${styles["theme-overlay__bar"]} prevent-nav`}>
                <Container>
                    <div className={`${styles["theme-overlay__bar-header"]} prevent-nav`}>
                        <Link
                            className={`${styles["tagline"]} prevent-nav`}
                            to={`${pathname}?q=${prevQuestion}&a=${prevAnswer}`}
                            onClick={() => onNavigateSlide("left")}>
                            &larr; PREVIOUS <span className={styles["theme-overlay__bar-header__answer"]}>ANSWER</span>
                        </Link>
                        <Link
                            to={`/${submissionData.baseUrl}`}
                            className={`${styles["theme-overlay__bar-header__close"]} ${styles["tagline"]} prevent-nav`}
                            onClick={onClose}>
                            CLOSE
                        </Link>
                        <Link
                            className={`${styles["tagline"]} prevent-nav`}
                            to={`${pathname}?q=${nextQuestion}&a=${nextAnswer}`}
                            onClick={() => onNavigateSlide("right")}>
                            NEXT <span className={styles["theme-overlay__bar-header__answer"]}>ANSWER</span> &rarr;
                        </Link>
                    </div>
                </Container>
            </div>

            <div
                className={styles["theme-overlay__content"]}
                // onMouseMove={onMouseMoveHandler}
                // onMouseOver={onMouseEnterHandler}
                // onMouseOut={onMouseLeaveHandler}
            >
                {imagePath && (
                    <div className={styles["theme-overlay__content-image"]}>
                        {submissionData.image.length > 1 && (
                            <>
                                <button
                                    className={`${styles["theme-overlay__content-image__button"]} ${styles["theme-overlay__content-image__button--left"]}`}
                                    onClick={() => onImageSlide("left")}>
                                    <svg viewBox='0 0 24 24' width='24px' height='24px' x='0' y='0'>
                                        <path d='M10,12l6,8.94L14.45,22,8.26,12.85a1.5,1.5,0,0,1,0-1.69L14.45,2,16,3.06Z' />
                                    </svg>
                                </button>
                                <button
                                    className={`${styles["theme-overlay__content-image__button"]} ${styles["theme-overlay__content-image__button--right"]}`}
                                    onClick={() => onImageSlide("right")}>
                                    <svg viewBox='0 0 24 24' width='24px' height='24px' x='0' y='0'>
                                        <path d='M14,12L8,3.06,9.55,2l6.19,9.15a1.5,1.5,0,0,1,0,1.69L9.55,22,8,20.94Z' />
                                    </svg>
                                </button>
                            </>
                        )}
                        <div className={styles["theme-overlay__content-image-wrapper"]} ref={refImages}>
                            {submissionData.image.map(([image], index) => {
                                return (
                                    <div key={index} className={styles["theme-overlay__content-image__item"]}>
                                        <LazyLoadImage src={image.url} alt='Submission' loading='lazy' />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}

                {submissionData.video && (
                    <div className={`${styles["theme-overlay__content-image"]} ${styles["theme-overlay__content-video"]}`}>
                        <iframe
                            title='Video overlay'
                            className={styles["theme-overlay__content-video__frame"]}
                            src={vimeoUrl(submissionData.video)}
                            frameBorder='0'
                            allow='autoplay; fullscreen; picture-in-picture'
                            allowFullScreen></iframe>
                    </div>
                )}

                {submissionData.words && (
                    <>
                        {!imagePath && !submissionData.words && (
                            <div className={`${styles["theme-overlay__content-image"]} ${styles["title"]}`}>{submissionData.words}</div>
                        )}
                    </>
                )}

                <div className={styles["theme-overlay__content-text"]}>
                    <h3 className={`${styles["theme-overlay__content-question"]} ${styles["tagline"]}`}>{submissionData.question}</h3>

                    <div className={`${styles["theme-overlay__content-reflection"]} ${styles["body"]}`}>{submissionData.words}</div>

                    <div className={`${styles["theme-overlay__content-reflection"]} ${styles["body"]}`}>{submissionData.reflection}</div>
                </div>
            </div>

            <div className={`${styles["theme-overlay__bar"]} prevent-nav`}>
                <Container>
                    {submissionData.name && (
                        <p className={`${styles["theme-overlay__bar-name"]} prevent-nav`}>
                            <span className={styles["tagline"]}>Submission by</span> {instagramUrl(submissionData.name, styles)}
                        </p>
                    )}
                </Container>
            </div>
        </div>
    );
};

export default ThemeOverlay;
