import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import Prismic from "@prismicio/client";
import { client } from "./prismic-configuration";

import Header from "./components/_globals/header/Header";

import PageHome from "./pages/PageHome";
import PageAbout from "./pages/PageAbout";
import PageExplore from "./pages/PageExplore";
import PageThemes from "./pages/PageThemes";
import PageMembers from "./pages/PageMembers";
import PageTheme from "./pages/PageTheme";
import PageGeneric from "./pages/PageGeneric";
import PageArticle from "./pages/PageArticle";

import PageRegister from "./pages/PageRegister";
import PageLogin from "./pages/PageLogin";
import PagePasswordForget from "./pages/PagePasswordForget";
import PageAccount from "./pages/PageAccount";

import * as ROUTES from "./constants/routes";

import { withAuthentication, AuthUserContext } from "./session";
import { StoreContextProvider } from "./hooks/contexts";
import CookieNotification from "./components/_globals/cookie/CookieNotification";
import { RichText } from "prismic-reactjs";

const App = () => {
    const [isAllowedToLoad, setIsAllowedToLoad] = useState(false);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const fetchData = async () => {
            const response = await client.query(Prismic.Predicates.at("document.type", "globals"));
            if (response) {
                const pw = RichText.asText(response.results[0].data.website_password);
                if (pw && pw.length !== 0 && process.env.NODE_ENV !== "development") {
                    let alertBox = prompt("Password:");
                    if (alertBox === pw) {
                        setIsAllowedToLoad(true);
                    } else {
                        console.log("password is not correct");
                    }
                } else {
                    setIsAllowedToLoad(true);
                }
            }
        };
        fetchData();
    }, []);

    if (!isAllowedToLoad) {
        return false;
    }

    return (
        <Router>
            <Header />
            <CookieNotification />

            <StoreContextProvider>
                <AuthUserContext.Consumer>
                    {(authUser) => {
                        return (
                            <main>
                                <Route exact path={ROUTES.LANDING} component={authUser ? PageThemes : PageHome} />
                                <Route path={ROUTES.SIGN_UP} component={PageRegister} />
                                <Route path={ROUTES.SIGN_IN} component={PageLogin} />
                                <Route path={ROUTES.PASSWORD_FORGET} component={PagePasswordForget} />

                                <Route
                                    path={ROUTES.ABOUT}
                                    render={(props) => {
                                        return <PageAbout {...props} />;
                                    }}
                                />

                                <Route
                                    path={ROUTES.EXPLORE}
                                    render={(props) => {
                                        return authUser ? <PageExplore {...props} /> : <Redirect to={ROUTES.LANDING} />;
                                        // return <PageExplore {...props} />;
                                    }}
                                />
                                <Route
                                    path={ROUTES.MEMBERS}
                                    render={(props) => {
                                        return authUser ? <PageMembers {...props} /> : <Redirect to={ROUTES.LANDING} />;
                                        // return <PageMembers {...props} />;
                                    }}
                                />
                                <Route
                                    path={ROUTES.ACCOUNT}
                                    render={(props) => {
                                        return authUser ? <PageAccount {...props} /> : <Redirect to={ROUTES.LANDING} />;
                                        // return <PageAccount {...props} />;
                                    }}
                                />

                                <Route
                                    path={ROUTES.THEME}
                                    render={(props) => {
                                        return authUser ? <PageTheme {...props} /> : <Redirect to={ROUTES.LANDING} />;
                                        // return <PageTheme {...props}/>;
                                    }}
                                />
                                <Route
                                    path={ROUTES.GENERIC}
                                    render={(props) => {
                                        return <PageGeneric {...props} />;
                                    }}
                                />
                                <Route
                                    path={ROUTES.ARTICLE}
                                    render={(props) => {
                                        return <PageArticle {...props} />;
                                    }}
                                />
                            </main>
                        );
                    }}
                </AuthUserContext.Consumer>
            </StoreContextProvider>
        </Router>
    );
};

export default withAuthentication(App);
