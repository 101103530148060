import React, { useEffect, useState } from "react";

import Prismic from "@prismicio/client";
import { client } from "../prismic-configuration";

import SEO from "../components/_globals/seo/SEO";

import AboutIntroduction from "../components/about/about-introduction/AboutIntroduction";
import AboutPillars from "../components/about/about-pillars/AboutPillars";
import Footer from "../components/_globals/footer/Footer";

const PageAbout = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await client.query(Prismic.Predicates.at("document.type", "about"));
            if (response) {
                setData(response.results[0]);
            }
        };

        fetchData();
    }, []);

    if (data === null) {
        return null;
    }

    return (
        <>
            <SEO title='' />

            <AboutIntroduction {...data} />
            <AboutPillars {...data} />

            <Footer />
        </>
    );
};

export default PageAbout;
